import React, { useState, Fragment, useContext } from 'react'
import { Table, Head, Body, Row, HeadingCell } from '@toasttab/buffet-pui-table'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { fetchABElevationsForService, fetchService } from '../../queries'
import { useQuery } from 'react-query'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useParams } from 'react-router-dom'
import { ABElevationAddRow } from './ABElevationAddRow'
import { ABElevationRow } from './ABElevationRow'
import { NotificationContext } from '../../NotificationContainer'
import { PermissionsContext } from '../../PermissionsContext'

export default function ABElevationList() {
  const { env, serviceName } = useParams()
  const { setError } = useContext(NotificationContext)
  const { readOnly } = useContext(PermissionsContext)
  const {
    data: elevations,
    error: errorElevations,
    isLoading: isLoadingElevations,
    refetch: refetchElevations
  } = useQuery(
    ['fetchABElevationsForService', { env, serviceName }],
    () => fetchABElevationsForService({ env, serviceName }),
    {
      retry: 0,
      onError: (err) => {
        setError('Error loading AB Elevations', err)
      }
    }
  )
  const {
    data: serviceDeployments,
    error: errorDeployments,
    isLoading: isLoadingDeployments
  } = useQuery(
    ['fetchService', { env, serviceName }],
    () => fetchService({ env, serviceName }),
    {
      retry: 0,
      onError: (err) => {
        setError('Error loading Service Deployments', err)
      }
    }
  )

  const [showAddCell, setShowAddCell] = useState(false)
  const [rowEditingId, setRowEditingId] = useState(-1)

  const addCancelButtonToggle = () => {
    setShowAddCell(!showAddCell)
  }
  let component
  if (isLoadingElevations || isLoadingDeployments) {
    component = <MerryGoRound />
  } else if (errorElevations) {
    component = <div> Error loading AB Elevations </div>
  } else {
    component = (
      <Fragment>
        <Table className='table-fixed'>
          <Head>
            <Row>
              <HeadingCell className='w-96 text-center'>%</HeadingCell>
              <HeadingCell className='w-24 text-center'>Revision</HeadingCell>
              <HeadingCell className='w-36 text-center'>Deployment</HeadingCell>
              <HeadingCell />
            </Row>
          </Head>
          <Body>
            {[...elevations]
              .sort((e1, e2) =>
                e1.service.srevision
                  .toString()
                  .localeCompare(e2.service.srevision)
              )
              .map((elevation, i) => (
                <ABElevationRow
                  key={
                    elevation.service.deployment +
                    ':' +
                    elevation.service.srevision
                  }
                  elevation={elevation}
                  editing={rowEditingId === i}
                  setEditing={(b) => setRowEditingId(b ? i : -1)}
                  disabled={
                    (rowEditingId !== i && rowEditingId > 0) || showAddCell
                  }
                  onSuccess={() => {
                    return refetchElevations()
                  }}
                />
              ))}
            {showAddCell && (
              <ABElevationAddRow
                elevations={elevations}
                serviceDeployments={serviceDeployments}
                onSuccess={() => {
                  addCancelButtonToggle()
                  return refetchElevations()
                }}
              />
            )}
          </Body>
        </Table>
        {elevations.length === 0 && !showAddCell && (
          <p className='text-gray-50 w-full text-center'>
            No elevations found for service {serviceName}.
          </p>
        )}
      </Fragment>
    )
  }
  return (
    <>
      <h5 className='type-headline-5'>AB Elevations</h5>
      {component}
      <Button
        className='add-elevation-button'
        size='sm'
        onClick={() => addCancelButtonToggle()}
        disabled={
          readOnly ||
          isLoadingElevations ||
          errorElevations ||
          errorDeployments ||
          rowEditingId >= 0
        }
      >
        {showAddCell ? 'Cancel' : 'Add'}
      </Button>
    </>
  )
}
