import React from 'react'

import ServiceDefaults from './ServiceDefaults'
import ServiceDeploymentList from './ServiceDeploymentsList'
import ABElevationList from './ABElevation/ABElevationList'
import RestaurantElevationList from './RestaurantElevation/RestaurantElevationList'

export default function ServiceDetailsPage() {
  return (
    <div>
      <ServiceDefaults />
      <ServiceDeploymentList />
      <ABElevationList />
      <RestaurantElevationList />
    </div>
  )
}
