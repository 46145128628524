import { Cell, Row } from '@toasttab/buffet-pui-table'
import React, { Fragment, useContext, useState } from 'react'
import { Select } from '@toasttab/buffet-pui-dropdowns'
import {
  InCellCancelButton,
  InCellDeleteButton,
  InCellEditButton,
  InCellSubmitButton
} from '../EditingButtons'
import { useMutation } from 'react-query'
import {
  addRestaurantElevation,
  removeRestaurantElevation
} from '../../queries'
import { useParams } from 'react-router-dom'
import { NotificationContext } from '../../NotificationContainer'

export const RestaurantElevationRow = ({
  elevation,
  serviceDeployments,
  editing,
  setEditing,
  onSuccess,
  disabled
}) => {
  const { env, serviceName } = useParams()
  const { setError, setSuccess } = useContext(NotificationContext)
  const [addCellRevision, setAddCellRevision] = useState(
    elevation.service.srevision
  )
  const [addCellDeployment, setAddCellDeployment] = useState(
    elevation.service.deployment
  )

  const { mutate: removeElevation, isLoading: removeIsLoading } = useMutation(
    ({ restaurantGuid, version }) => {
      return removeRestaurantElevation({
        env,
        serviceName,
        restaurantGuid,
        version
      })
    },
    {
      onError: (err) => {
        setError('Error Deleting Restaurant Elevation', err)
      },
      onSuccess: (res, { restaurantGuid }) => {
        setSuccess(`Deleted Restaurant Elevation ${restaurantGuid}.`)
        return onSuccess()
      }
    }
  )
  const { mutate: submitElevation, isLoading: submitIsLoading } = useMutation(
    ({ restaurantGuid, version, revision, deployment }) =>
      addRestaurantElevation({
        env,
        serviceName,
        version,
        revision,
        deployment,
        restaurantGuid
      }),
    {
      onError: (err) => {
        setError(`Error Updating Restaurant Elevation:`, err)
      },
      onSuccess: (res, { restaurantGuid, version, revision, deployment }) => {
        setSuccess(
          `Updated Restaurant Elevation ${restaurantGuid} to direct traffic to V:${version} D:${deployment}.`
        )
        return onSuccess()
      }
    }
  )
  const RevisionInputCell = () => {
    return (
      <Cell>
        <Select
          options={[
            ...new Set(serviceDeployments.map((s) => s.srevision.toString()))
          ]}
          onChange={(val) => {
            setAddCellRevision(val)
          }}
          value={addCellRevision.toString()}
        />
      </Cell>
    )
  }

  const DeploymentInputCell = () => {
    return (
      <Cell>
        <Select
          options={[
            ...new Set(
              serviceDeployments.map((s) => s.deployment.toString()) //THIS DOESNT WORK WITHOUT TOSTRING... JAVASCRIPT
            )
          ]}
          onChange={(val) => setAddCellDeployment(val)}
          value={addCellDeployment.toString()}
        />
      </Cell>
    )
  }

  const ModifyElevationWidget = () => {
    return editing ? (
      <Fragment>
        <Cell>
          <InCellSubmitButton
            handleClick={() =>
              submitElevation({
                version: elevation.service.sversion,
                revision: addCellRevision,
                deployment: addCellDeployment,
                restaurantGuid: elevation.restaurantGuid.toString()
              })
            }
          />
        </Cell>
        <Cell>
          <InCellCancelButton
            handleClick={() => setEditing(false)}
            disabled={false}
          />
        </Cell>
      </Fragment>
    ) : (
      <Fragment>
        <InCellEditButton
          handleClick={() => {
            setAddCellRevision(elevation.service.srevision.toString())
            setAddCellDeployment(elevation.service.deployment.toString())
            setEditing(true)
          }}
          disabled={submitIsLoading || removeIsLoading || disabled}
        />
        <InCellDeleteButton
          handleClick={() =>
            removeElevation({
              version: elevation.service.sversion,
              restaurantGuid: elevation.restaurantGuid
            })
          }
          disabled={submitIsLoading || removeIsLoading || disabled}
        />
      </Fragment>
    )
  }

  return (
    <Row>
      <Cell>{elevation.restaurantGuid}</Cell>
      {editing ? (
        <RevisionInputCell />
      ) : (
        <Cell>{elevation.service.srevision}</Cell>
      )}
      {editing ? (
        <DeploymentInputCell />
      ) : (
        <Cell>{elevation.service.deployment}</Cell>
      )}
      <Cell>
        <span>
          <ModifyElevationWidget />
        </span>
      </Cell>
    </Row>
  )
}
