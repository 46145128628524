import React, { useContext, useEffect, useRef, useState } from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button } from '@toasttab/buffet-pui-buttons'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { errorOrMessageToString } from './util'
import { requiresConfirm } from './SafetyInspector'

export const NotificationContext = React.createContext({
  hideNotification: () => null,
  setSuccess: () => null,
  setError: (mesage, err) => null,
  confirm: ({
    operationName,
    onConfirm,
    service,
    serviceDeployments,
    newService,
    cluster,
    newCluster
  }) => null
})

export function NotificationContainer({
  notification: {
    variant = 'neutral',
    message = '',
    confirmValue = undefined,
    onConfirm = undefined,
    operationName = undefined
  } = {
    variant: 'neutral',
    message: '',
    confirmValue: undefined,
    onConfirm: undefined,
    operation: undefined
  }
}) {
  const { hideNotification } = useContext(NotificationContext)
  const [confirmText, setConfirmText] = useState('')
  const input = useRef(null)
  useEffect(() => {
    if (message.length > 0 && operationName && input.current) {
      input.current.focus()
    }
  }, [message, operationName])
  if (message.length === 0) {
    return null
  }
  const conf = () => {
    hideNotification()
    setConfirmText('')
    onConfirm()
  }
  return (
    // TODO: The standard component for this notification pattern is @toasttab/buffet-pui-snackbars
    <div className='absolute right-0 z-30 flex justify-center w-full top-2'>
      <Alert
        variant={variant}
        showIcon
        onDismiss={() => hideNotification()}
        className='shadow-lg'
      >
        <div className='flex flex-col max-w-lg'>
          <p className='mb-4'>{message}</p>
          {confirmValue && (
            <TextInput
              ref={input}
              size='base'
              value={confirmText}
              onChange={(e) => {
                setConfirmText(e.target.value)
              }}
              onKeyPress={(e) => {
                // handle enter key
                if (e.charCode === 13 && confirmValue === confirmText) {
                  conf()
                }
              }}
            />
          )}
          {operationName && (
            <Button
              className='mt-4'
              size='xs'
              disabled={
                confirmValue !== undefined && confirmValue !== confirmText
              }
              onClick={() => {
                conf()
              }}
            >
              {operationName}
            </Button>
          )}
        </div>
      </Alert>
    </div>
  )
}
export function getNotificationProviderValue({ setNotification }) {
  return {
    hideNotification: () => setNotification(),
    setError: (message, err) =>
      setNotification({
        variant: 'error',
        message: err ? `${message}: ${errorOrMessageToString(err)}` : message
      }),
    setSuccess: (message) =>
      setNotification({
        variant: 'success',
        message: errorOrMessageToString(message)
      }),
    confirm: ({
      operationName,
      onConfirm,
      service,
      serviceDeployments,
      newService,
      cluster,
      newCluster
    }) => {
      const confirmLevel = requiresConfirm({
        operationName,
        service,
        serviceDeployments,
        newService,
        cluster,
        newCluster
      })
      if (confirmLevel) {
        let variant = 'neutral'
        let message = confirmLevel.message
        let confirmValue = undefined
        if (confirmLevel.level === 'Critical') {
          variant = 'error'
          confirmValue = service.name
          message = `${confirmLevel.message}
                Please enter the name of the service: "${service.name}" to confirm`
        }
        setNotification({
          variant,
          confirmValue,
          message,
          onConfirm,
          operationName
        })
      } else {
        onConfirm()
      }
    }
  }
}
