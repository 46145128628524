import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import EnvSwitcher from './EnvSwitcher'
import ServiceDetailsPage from './ServiceDetails/ServiceDetailsPage'
import {
  NotificationContainer,
  NotificationContext,
  getNotificationProviderValue
} from './NotificationContainer'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import ClusterManagementPage from './Cluster/ClusterManagementPage'
import {
  ClusterDetailsPage,
  ClusterCreationPage
} from './Cluster/ClusterDetailsPage'
import {
  EnvRouteMapConfigContext,
  getEnvRouteMapConfig
} from './EnvRouteMapConfig'
import { PermissionsContext } from './PermissionsContext'

const SvcmgmtPage = ({ username }) => {
  const [notification, setNotification] = useState({
    variant: 'success',
    message: ''
  })
  const [envRouteMapConfig, setEnvRouteMapConfig] = useState()
  useEffect(() => {
    setEnvRouteMapConfig(
      getEnvRouteMapConfig(window.location.protocol === 'http:')
    )
  }, [])
  const queryClient = new QueryClient({
    queryCache: new QueryCache(),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
          if ([401, 403].includes(error.status)) {
            return false
          }
          return failureCount < 3
        }
      }
    }
  })
  return (
    <QueryClientProvider client={queryClient}>
      <EnvRouteMapConfigContext.Provider value={envRouteMapConfig}>
        <NotificationContext.Provider
          value={getNotificationProviderValue({ setNotification })}
        >
          <PermissionsContext.Provider value={{ readOnly: true }}>
            {envRouteMapConfig ? (
              <BrowserRouter>
                <Switch>
                  <Route
                    path='/:serviceName/elevations/'
                    exact={true}
                    render={({
                      match: {
                        params: { serviceName }
                      }
                    }) => (
                      <Redirect
                        to={`/${serviceName}/elevations/${envRouteMapConfig[0].key}`}
                      />
                    )}
                  />
                  <Route
                    path='/:serviceName/clusters/'
                    exact={true}
                    render={({
                      match: {
                        params: { serviceName }
                      }
                    }) => (
                      <Redirect
                        to={`/${serviceName}/clusters/${envRouteMapConfig[0].key}`}
                      />
                    )}
                  />
                  <Route
                    path='/:serviceName/:view/:env/:operation?/:id?'
                    render={({
                      match: {
                        params: { serviceName, view, env, operation }
                      }
                    }) => {
                      let element
                      if (view === 'elevations') {
                        element = <ServiceDetailsPage />
                      } else if (view === 'clusters') {
                        if (!operation) {
                          element = <ClusterManagementPage />
                        } else if (operation === 'create') {
                          element = <ClusterCreationPage />
                        } else if (operation === 'edit') {
                          element = <ClusterDetailsPage />
                        } else {
                          element = null
                        }
                      }
                      return (
                        <div className='my-2 md:my-6 md:ml-4'>
                          <NotificationContainer notification={notification} />
                          <EnvSwitcher />
                          {element}
                        </div>
                      )
                    }}
                  />
                </Switch>
              </BrowserRouter>
            ) : (
              <MerryGoRound />
            )}
          </PermissionsContext.Provider>
        </NotificationContext.Provider>
        <ReactQueryDevtools />
      </EnvRouteMapConfigContext.Provider>
    </QueryClientProvider>
  )
}

export default SvcmgmtPage
