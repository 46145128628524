import LocalJWTManager from './LocalJWTManager'
import { AllEnvsRouteMapConfig } from './EnvRouteMapConfig'
const jwtManager = new LocalJWTManager('tadpoleJWT')

function securedFetch(env, url, init = {}) {
  // TODO: post requests disabled for demo - update when permissions are set up
  if (['DELETE', 'PUT', 'POST', 'PATCH'].includes(init.method)) {
    throw new Error(`${init.method} requests disabled`)
  }

  const jwtHeader = jwtManager.encodedToken
    ? { Authorization: `Bearer ${jwtManager.encodedToken}` }
    : null
  if (jwtHeader) {
    init.headers = { ...(init.headers || {}), ...jwtHeader }
  }
  const server = AllEnvsRouteMapConfig.find((o) => o.key === env)
  if (server && server.routes.elevations) {
    return fetch(server.routes.elevations + url, init)
  }
  throw new Error(`Env ${env} not configured`)
}
export const checkResponse = (response) => {
  if (response.redirected && !response.ok) {
    return fetch(response.url).then(checkResponse)
  }

  if (!response.ok) {
    response.message = `Error while executing request: ${response.url}. Status: ${response.status}`
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

const toJson = (response) => {
  return checkResponse(response).then((r) => r.json())
}

/*TODO HAI-366  this is a hack
   serviceName is actually the git-repo-name from tadpole at the moment
   eventually we'll want to actually get passed a serviceName
   however at the moment all serviceNames drop the 'toast-' except for
     * toast-welcome-survey
     * toast-invoice-admin-web
     * toast-for-good-bff
     * toast-administration-spas-manifest
     * toast-now-orders-ui
     * toast-onboarding-checklist-spa
     * toast-pre-kickoff-survey
     * toast-onboarding-exp-fe
   most of these look like SPAs anyways

*/
const serviceNameTransformer = (serviceName) => serviceName.replace(/^toast-/i, '')

//GET

export const fetchAllServices = ({ env, clusterGuid }) =>
  securedFetch(env, `/v4/services`).then(toJson)

export const fetchAllServicesByCluster = ({ env, clusterGuid }) =>
  securedFetch(env, `/v4/services?clusterGuid=${clusterGuid}`).then(toJson)

export const fetchService = ({ env, serviceName }) =>
  securedFetch(env, `/v4/services/${serviceNameTransformer(serviceName)}`).then(toJson)

export const fetchAllClusters = ({ env }) =>
  securedFetch(env, `/v4/clusters`).then(toJson)

export const fetchABElevationsForService = ({ env, serviceName }) =>
  securedFetch(env, `/v4/abtest/${serviceNameTransformer(serviceName)}`).then(toJson)

export const fetchRestaurantElevationsForService = ({ env, serviceName }) =>
  securedFetch(env, `/v4/elevaterestaurant?service=${serviceNameTransformer(serviceName)}`).then(toJson)

export const fetchCluster = ({ env, clusterGuid }) =>
  securedFetch(env, `/v4/clusters/${clusterGuid}`).then(toJson)

export const fetchServiceMetadata = ({ env, serviceName }) =>
securedFetch(env, `/v4/services/${serviceNameTransformer(serviceName)}/metadata`).then(toJson)

// DELETE/PUT/POST/PATCH
export const removeABElevation = ({
  env,
  serviceName,
  version,
  revision,
  deployment
}) =>
  securedFetch(
    env,
    `/v4/abtest/${serviceNameTransformer(serviceName)}/${version}/${revision}?deployment=${deployment}`,
    { method: 'DELETE' }
  ).then(checkResponse)

export const addABElevation = ({
  env,
  serviceName,
  version,
  revision,
  deployment,
  percentage
}) =>
  securedFetch(
    env,
    `/v4/abtest/${serviceNameTransformer(serviceName)}/${version}/${revision}?deployment=${deployment}&confirm=true`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ percentageOfRequests: percentage / 100 })
    }
  ).then(checkResponse)

export const removeRestaurantElevation = ({
  env,
  restaurantGuid,
  serviceName,
  version
}) =>
  securedFetch(
    env,
    `/v4/elevaterestaurant/${restaurantGuid}/${serviceNameTransformer(serviceName)}/${version}?confirm=true`,
    {
      method: 'DELETE'
    }
  ).then(toJson)

export const addRestaurantElevation = ({
  env,
  restaurantGuid,
  serviceName,
  version,
  revision,
  deployment
}) =>
  securedFetch(
    env,
    `/v4/elevaterestaurant/${restaurantGuid}/${serviceNameTransformer(serviceName)}/${version}?deployment=${deployment}&confirm=true`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ revision })
    }
  ).then(toJson)

export const createCluster = ({ env, clusterRep }) =>
  securedFetch(env, `/v4/clusters/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(clusterRep)
  }).then(toJson)

export const updateCluster = ({ env, clusterGuid, clusterRep }) =>
  securedFetch(env, `/v4/clusters/${clusterGuid}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(clusterRep)
  }).then(toJson)

export const updateServiceStatus = ({ env, service, active }) =>
  securedFetch(
    env,
    `/v4/services/${serviceNameTransformer(service.name)}/${service.sversion}/${service.srevision}/${service.deployment}?confirm=true`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...service, active })
    }
  ).then(toJson)

export const updateAuthZ = ({ env, service, envoyAuthzEnabled }) =>
  securedFetch(
    env,
    `/v4/services/${serviceNameTransformer(service.name)}/${service.sversion}/${service.srevision}/${service.deployment}?confirm=true`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...service, envoyAuthzEnabled })
    }
  ).then(toJson)

export const modifyServiceEnvoyAuthzEnabled = ({ env, serviceName, enabled }) =>
  securedFetch(env, `/v4/services/${serviceNameTransformer(serviceName)}/metadata/envoyAuthzEnabled`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ envoyAuthzEnabled: enabled })
  }).then(checkResponse)
