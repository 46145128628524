import { Link, useParams } from 'react-router-dom'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { fetchAllClusters } from '../queries'

import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { NotificationContext } from '../NotificationContainer'

export function ClusterList({ service }) {
  const { env } = useParams()
  const { setError } = useContext(NotificationContext)
  const {
    data: clustersMap,
    error: errorClusters,
    isLoading: isLoadingClusters
  } = useQuery(['fetchAllClusters', { env }], () => fetchAllClusters({ env }), {
    onError: (err) => setError('There was an error loading clusters', err),
    select: (clusters) =>
      clusters.reduce((acc, v) => ({ ...acc, [v.guid]: v }), {
        default: { name: 'default' }
      })
  })

  if (isLoadingClusters) {
    return <MerryGoRound />
  }

  if (errorClusters) {
    return <div>Error</div>
  }

  return (
    <div>
      {Object.keys(service.serviceItems)
        .sort()
        .map((itemKey) => (
          <div key={itemKey}>
            {itemKey === 'default' ? (
              <span>default</span>
            ) : (
              <Link to={`/toastweb/elevations/${env}/clusters/edit/${itemKey}`}>
                {itemKey in clustersMap
                  ? clustersMap[itemKey].name
                  : `Missing Cluster ${itemKey}`}
              </Link>
            )}
          </div>
        ))}
    </div>
  )
}
