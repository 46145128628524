import React, { useContext } from 'react'

import { useQuery } from 'react-query'
import { fetchAllClusters } from '../queries'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { Link, useParams } from 'react-router-dom'
import { errorOrMessageToString } from '../util'
import { PermissionsContext } from '../PermissionsContext'

const ClusterRow = ({ cluster }) => {
  const { serviceName, view, env } = useParams()
  return (
    <Row>
      <Cell>
        <Link to={`/${serviceName}/${view}/${env}/edit/${cluster.guid}`}>
          {cluster.name}
        </Link>
      </Cell>
      <Cell>{cluster.guid}</Cell>
      <Cell>{cluster.status}</Cell>
    </Row>
  )
}

export const ClusterManagementPage = () => {
  const { serviceName, view, env } = useParams()
  const {
    data: clusters,
    error: errorClusters,
    isLoading: isLoadingClusters
  } = useQuery(['fetchAllClusters', { env }], () => fetchAllClusters({ env }))
  const { readOnly } = useContext(PermissionsContext)
  if (isLoadingClusters) {
    return <MerryGoRound />
  }

  if (errorClusters) {
    return <p>An error happened: {errorOrMessageToString(errorClusters)}</p>
  }

  if (clusters.length === 0 || !clusters) {
    return <p>No clusters found.</p>
  }

  return (
    <>
      <div className='flex pt-4 space-x-4'>
        <Link to={readOnly ? '#' : `/${serviceName}/${view}/${env}/create`}>
          <Button
            iconRight={<ChevronRightIcon />}
            size='sm'
            disabled={readOnly}
          >
            Create Cluster
          </Button>
        </Link>
      </div>
      <div>
        <Table>
          <Head>
            <Row>
              <HeadingCell>Name</HeadingCell>
              <HeadingCell>GUID</HeadingCell>
              <HeadingCell>Status</HeadingCell>
            </Row>
          </Head>
          <Body>
            <Row>
              <Cell>
                <Link to={`/${serviceName}/${view}/${env}/edit/default`}>
                  null / default
                </Link>
              </Cell>
              <Cell></Cell>
              <Cell></Cell>
            </Row>
            {[...clusters]
              .sort((c1, c2) => c1.name.localeCompare(c2.name))
              .map((cluster) => (
                <ClusterRow key={cluster.name} cluster={cluster} />
              ))}
          </Body>
        </Table>
      </div>
    </>
  )
}

export default ClusterManagementPage
