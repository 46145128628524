import { Cell, Row } from '@toasttab/buffet-pui-table'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Select } from '@toasttab/buffet-pui-dropdowns'
import { InCellSubmitButton } from '../EditingButtons'
import { useMutation } from 'react-query'
import { addABElevation } from '../../queries'
import { useParams } from 'react-router-dom'
import { NotificationContext } from '../../NotificationContainer'

export const ABElevationAddRow = ({ serviceDeployments, onSuccess }) => {
  const { env, serviceName } = useParams()
  const { setError, setSuccess } = useContext(NotificationContext)
  const last = serviceDeployments[serviceDeployments.length - 1]
  const [revisionInput, setAddCellRevision] = useState(
    last.srevision.toString()
  )
  const [deploymentInput, setAddCellDeployment] = useState(
    last.deployment.toString()
  )
  const [percentageInput, setAddElevationInput] = useState(0)
  const { mutate, isLoading } = useMutation(
    ({ percentage, version, revision, deployment }) =>
      addABElevation({
        env,
        serviceName,
        version,
        revision,
        deployment,
        percentage
      }),
    {
      onError: (err) => {
        setError('Error Adding AB Elevation', err)
      },
      onSuccess: (data, { percentage, version, revision, deployment }) => {
        setSuccess(
          `Added AB Elevation ${serviceName} v${revision}|${deployment} with ${percentage.toFixed(
            0
          )}% of traffic`
        )
        return onSuccess()
      }
    }
  )
  const input = useRef(null)
  useEffect(() => input.current.focus(), []) // focus on percentage input when this row is created
  return (
    <Row>
      <Cell>
        <input
          ref={input}
          type='number'
          step={1}
          className='in-cell-input'
          onChange={(event) => setAddElevationInput(event.target.value)}
          value={percentageInput}
        />
      </Cell>
      <Cell>
        <Select
          placeholder='Select'
          options={[
            ...new Set(serviceDeployments.map((s) => s.srevision.toString()))
          ]}
          onChange={(val) => setAddCellRevision(val)}
          value={revisionInput}
        />
      </Cell>

      <Cell>
        <Select
          placeholder='Select'
          options={[
            ...new Set(serviceDeployments.map((s) => s.deployment.toString()))
          ]}
          onChange={(val) => setAddCellDeployment(val)}
          value={deploymentInput}
        />
      </Cell>
      <Cell className='flex flex-row justify-end'>
        <InCellSubmitButton
          disabled={isLoading}
          handleClick={() =>
            mutate({
              version: serviceDeployments[0].sversion,
              revision: revisionInput,
              deployment: deploymentInput,
              percentage: percentageInput
            })
          }
        />
      </Cell>
    </Row>
  )
}
