import React, { useContext } from 'react'
import { IconButton, Button } from '@toasttab/buffet-pui-buttons'
import { DeleteIcon, EditIcon } from '@toasttab/buffet-pui-icons'
import { PermissionsContext } from '../PermissionsContext'

const InCellSubmitButton = ({ handleClick, disabled }) => {
  const { readOnly } = useContext(PermissionsContext)
  return (
    <Button disabled={readOnly || disabled} size='sm' onClick={handleClick}>
      Submit
    </Button>
  )
}

const InCellCancelButton = ({ handleClick, disabled }) => {
  const { readOnly } = useContext(PermissionsContext)
  return (
    <Button disabled={readOnly || disabled} className='ml-4' size='sm' onClick={handleClick}>
      Cancel
    </Button>
  )
}

const InCellEditButton = ({ handleClick, disabled }) => {
  const { readOnly } = useContext(PermissionsContext)
  return (
    <IconButton
      disabled={readOnly || disabled}
      size='sm'
      onClick={handleClick}
      icon={<EditIcon />}
    />
  )
}

const InCellDeleteButton = ({ handleClick, disabled }) => {
  const { readOnly } = useContext(PermissionsContext)
  return (
    <IconButton
      disabled={readOnly || disabled}
      size='sm'
      onClick={handleClick}
      icon={<DeleteIcon />}
    />
  )
}

export {
  InCellSubmitButton,
  InCellEditButton,
  InCellDeleteButton,
  InCellCancelButton
}
