import React from 'react'

import {
  Table,
  Head,
  Body,
  Row,
  HeadingCell,
  Cell
} from '@toasttab/buffet-pui-table'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { fetchAllServicesByCluster } from '../queries'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { errorOrMessageToString, writeToCsvBlob } from '../util'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { saveAs } from 'file-saver'
import { DownloadIcon } from '@toasttab/buffet-pui-icons'

export const Service = ({ service, env }) => (
  <Row>
    <Cell>
      <Link to={`/${service.name}/elevations/${env}/`}>{service.name}</Link>
    </Cell>
    <Cell>
      <ul>
        {service.deployments.map((s) => (
          <li
            key={s.srevision + '.' + s.deployment}
            className={!s.active ? 'font-thin text-color-disabled italic' : ''}
          >
            <span className='w-24 inline-block'>V: {s.srevision}</span>
            <span className='w-24 inline-block'>D: {s.deployment}</span>
            {!s.active && <span>inactive</span>}
          </li>
        ))}
      </ul>
    </Cell>
  </Row>
)

export const ServicesList = ({ clusterGuid, searchName = '' }) => {
  const { env } = useParams()

  const {
    data: services,
    error: errorServices,
    isLoading: isLoadingServices
  } = useQuery(['fetchAllServicesByCluster', { env, clusterGuid }], () =>
    fetchAllServicesByCluster({ env, clusterGuid })
  )

  if (isLoadingServices) {
    return <MerryGoRound />
  }

  if (errorServices) {
    return <p>An error happened: {errorOrMessageToString(errorServices)}</p>
  }

  if (services.length === 0) {
    return <p>No services found.</p>
  }
  const dedupedServices = [...services].reduce(
    (
      acc,
      {
        name,
        srevision,
        deployment,
        active,
        resourceType,
        includeVersionInPath
      }
    ) => {
      if (acc[name]) {
        return {
          ...acc,
          [name]: {
            name,
            deployments: acc[name].deployments.concat([
              { srevision, deployment, active }
            ]),
            resourceType,
            includeVersionInPath
          }
        }
      } else {
        return {
          ...acc,
          [name]: {
            name,
            deployments: [{ srevision, deployment, active }],
            resourceType,
            includeVersionInPath
          }
        }
      }
    },
    {}
  )
  const filteredAndSortedServices = Object.values(dedupedServices)
    .filter((s) => searchName.length === 0 || s.name.includes(searchName))
    .sort((s1, s2) => s1.name.localeCompare(s2.name))
  return (
    <>
      <Table>
        <Head>
          <Row>
            <HeadingCell className='w-96'>Service Name</HeadingCell>
            <HeadingCell>Deployments</HeadingCell>
          </Row>
        </Head>
        <Body>
          {filteredAndSortedServices.map((service) => (
            <Service key={service.name} env={env} service={service} />
          ))}
        </Body>
      </Table>
      <span>
        Download CSV
        <IconButton
          icon={<DownloadIcon />}
          onClick={() => {
            const headers = ['service', 'includeVersionInPath', 'resourceType']
            const data = filteredAndSortedServices.map((s) => [
              s.name,
              s.includeVersionInPath,
              s.resourceType
            ])
            saveAs(writeToCsvBlob(headers, data), `services.csv`)
          }}
        />
      </span>
    </>
  )
}
