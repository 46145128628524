import React from 'react'
import ReactDOM from 'react-dom'
import SvcmgmtPage from './app/SvcmgmtPage'
import { banquetSingleSpaReact } from '@toasttab/banquet-single-spa-react'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  cssScope: 'data-scale-svcmgmt',
  rootComponent: SvcmgmtPage
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
