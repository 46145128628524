import { useHistory, useLocation, useParams } from 'react-router-dom'
import { EnvRouteMapConfigContext } from './EnvRouteMapConfig'
import React, { useContext } from 'react'
import { Tabs, TabList, Tab } from '@toasttab/buffet-pui-tabs'

export default function EnvSwitcher() {
  const history = useHistory()
  const location = useLocation()
  const { serviceName, view } = useParams()
  const basePath = `/${serviceName}/${view}`
  const envRouteMapConfig = useContext(EnvRouteMapConfigContext)
  return (
    <div className='flex flex-col mb-4'>
      <Tabs
        className='w-full'
        defaultIndex={envRouteMapConfig.findIndex((env) =>
          location.pathname.startsWith(`${basePath}/${env.key}`)
        )}
        onChange={(index) => {
          const env = envRouteMapConfig[index]
          history.push(`${basePath}/${env.key}`)
        }}
      >
        <TabList className='w-full px-4 md:px-0'>
          {envRouteMapConfig.map(({ title, key }) => (
            <Tab key={key}>{title}</Tab>
          ))}
        </TabList>
      </Tabs>
    </div>
  )
}
