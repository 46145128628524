type ElevationOperation =
  | 'Activate Service'
  | 'Deactivate Service'
  | 'Add Restaurant Elevation'
  | 'Remove Restaurant Elevation'
  | 'Update Restaurant Elevation'
  | 'Add Percentage Elevation'
  | 'Remove Percentage Elevation'
  | 'Update Percentage Elevation'
  | 'Default Envoy Authz'
  | 'Undefault Envoy Authz'
  | 'Update Cluster'
  | 'Create Cluster'
  | 'Enable AuthZ'
  | 'Disable AuthZ'
type ConfirmLevel =
  | 'Warning'
  | 'Critical'
type ConfirmResponse =
  | false
  | { message: string, level: ConfirmLevel}
interface ServiceItem {
  clusterGuid: string
  uri: string
  comment: string
}

interface Service {
  name: string
  sversion: string
  srevision: string
  deployment: string
  active: boolean
  comment: string
  includeVersionInPath: boolean
  envoyAuthzEnabled: boolean
  resourceType: string
  serviceItems: ServiceItem
}
interface Cluster {
  name: string
  guid: string
  status: ClusterStatus
}
type ClusterStatus = 'HEALTHY' | 'UNHEALTHY' | 'INACTIVE'
interface SafetyRequest {
  operationName: ElevationOperation
  service: Service
  newService: Service
  serviceDeployments: Array<Service>
  cluster: Cluster
  newCluster: Cluster
}
export function requiresConfirm({operationName,service,serviceDeployments,newService,cluster,newCluster}:SafetyRequest): ConfirmResponse {
  console.log('safety check',operationName,service,serviceDeployments)
  const activeCount = (serviceDeployments || []).reduce((activeCount,s)=>s.active ? activeCount+1 : activeCount,0)
  switch (operationName) {
    case 'Deactivate Service':
      if(activeCount > 1) {
        return false
      } else {
        return {
          level:'Critical',
          message:`This is the last deployment of ${service.name}, deactivating this will render all requests to ${service.name} unroutable.`
        }
      }

    case 'Update Restaurant Elevation':
      if(service.srevision < newService.srevision || (service.srevision == newService.srevision && service.deployment < newService.deployment)){
        return {
          level:'Warning',
          message:`This operation will regress the version of ${service.name} for this restaurant, make sure it is backwards compatible.`
        }
      } else {
        return false
      }
    case 'Update Cluster':
      return {
        level:'Warning',
        message:`Updating the cluster could have significant downtime implications`
      }
    case 'Disable AuthZ':
      return {
        level: 'Warning',
        message: 'Disabling AuthZ may have secuity implications'
      }
    case 'Activate Service':
    case 'Add Restaurant Elevation':
    case 'Remove Restaurant Elevation':
    case 'Default Envoy Authz':
    case 'Undefault Envoy Authz':
    case 'Add Percentage Elevation':
    case 'Remove Percentage Elevation':
    case 'Update Percentage Elevation':
    case 'Create Cluster':
    case 'Enable AuthZ':
      return false;
    default:
      return {
        level:'Warning',
        message:`Warning`
      }
  }
}
