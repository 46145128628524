import { Cell, Row } from '@toasttab/buffet-pui-table'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Select } from '@toasttab/buffet-pui-dropdowns'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { InCellSubmitButton } from '../EditingButtons'
import { useParams } from 'react-router-dom'
import { NotificationContext } from '../../NotificationContainer'
import { useMutation } from 'react-query'
import { addRestaurantElevation } from '../../queries'

export const RestaurantElevationAddRow = ({
  serviceDeployments,
  onSuccess
}) => {
  const { env, serviceName } = useParams()
  const { setError, setSuccess } = useContext(NotificationContext)
  const last = serviceDeployments[serviceDeployments.length - 1]
  const [addCellRevision, setAddCellRevision] = useState(
    last.srevision.toString()
  )
  const [addCellDeployment, setAddCellDeployment] = useState(
    last.deployment.toString()
  )
  const [addRestaurantGuidInput, setAddRestaurantGuidInput] = useState('')

  const { mutate, isLoading } = useMutation(
    ({ restaurantGuid, version, revision, deployment }) =>
      addRestaurantElevation({
        env,
        serviceName,
        version,
        revision,
        deployment,
        restaurantGuid
      }),
    {
      onError: (err) => {
        setError('Error Creating Restaurant Elevation', err)
      },
      onSuccess: (res, { restaurantGuid, version, revision, deployment }) => {
        setSuccess(
          `Created Restaurant Elevation ${restaurantGuid} directing traffic to V:${version} D:${deployment}.`
        )
        return onSuccess()
      }
    }
  )
  const input = useRef(null)
  useEffect(() => input.current.focus(), []) // focus on restaurant guid input when this row is created
  return (
    <Row>
      <Cell>
        <input
          ref={input}
          type='text'
          className='in-cell-input w-80'
          onChange={(event) => setAddRestaurantGuidInput(event.target.value)}
          value={addRestaurantGuidInput}
          autoFocus
        />
      </Cell>
      <Cell>
        <Select
          options={[
            ...new Set(serviceDeployments.map((s) => s.srevision.toString()))
          ]}
          onChange={(val) => setAddCellRevision(val)}
          value={addCellRevision}
        />
      </Cell>
      <Cell>
        <Select
          options={[
            ...new Set(
              serviceDeployments.map((s) => s.deployment.toString()) //THIS DOESNT WORK WITHOUT TOSTRING... JAVASCRIPT
            )
          ]}
          onChange={(val) => setAddCellDeployment(val)}
          value={addCellDeployment}
        />
      </Cell>
      <Cell className='flex flex-row justify-end'>
        {isLoading ? (
          <MerryGoRound size={MerryGoRound.Size.xxs} />
        ) : (
          <InCellSubmitButton
            disabled={isLoading}
            handleClick={() => {
              mutate({
                version: serviceDeployments[0].sversion,
                revision: addCellRevision,
                deployment: addCellDeployment,
                restaurantGuid: addRestaurantGuidInput.toString()
              })
            }}
          />
        )}
      </Cell>
    </Row>
  )
}
