import React, { useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { ServicesList } from './ServicesList'
import { useMutation, useQuery } from 'react-query'
import { createCluster, fetchCluster, updateCluster } from '../queries'
import { Form, Formik } from 'formik'
import {
  TextInputField,
  SelectField,
  SubmitButton
} from '@toasttab/buffet-pui-forms'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { NotificationContext } from '../NotificationContainer'
import { PermissionsContext } from '../PermissionsContext'

const ServicesInCluster = ({ clusterGuid }) => {
  return (
    <>
      <h3>Services in this cluster</h3>
      <ServicesList clusterGuid={clusterGuid} />
    </>
  )
}

const ClusterDetailsContainer = ({ isCreation }) => {
  const history = useHistory()
  const { env, id: clusterGuid, serviceName } = useParams()
  console.log('clusterGuid', clusterGuid)
  const { setError, setSuccess, confirm } = useContext(NotificationContext)
  const { readOnly } = useContext(PermissionsContext)

  const {
    data: cluster,
    error: errorGetCluster,
    isLoading: isLoadingCluster,
    refetch
  } = useQuery(
    ['fetchCluster', { env, clusterGuid }],
    () => fetchCluster({ env, clusterGuid }),
    {
      retry: 0,
      enabled: !isCreation && clusterGuid !== 'default',
      onError: (err) => {
        setError(`There was an error loading the cluster`, err)
      }
    }
  )

  const { mutate: mutateCreateCluster, isLoading: isCreating } = useMutation(
    (vars) => createCluster(vars),
    {
      onSuccess: (data, vars) => {
        setSuccess('Created Cluster Successfully')
        history.push(`/${serviceName}/clusters/${env}/edit/${data.guid}`)
      },
      onError: (err) => {
        setError('There was an error creating the cluster', err)
      }
    }
  )
  const { mutate: mutateUpdateCluster, isLoading: isUpdating } = useMutation(
    (vars) => updateCluster(vars),
    {
      onSuccess: (data, vars) => {
        setSuccess('Updated Cluster Successfully')
        return refetch()
      },
      onError: (err) => {
        setError('There was an error updating the cluster', err)
      }
    }
  )

  const onSubmitCluster = (values) => {
    const operationName = isCreation ? 'Create Cluster' : 'Update Cluster'
    confirm({
      operationName,
      onConfirm: () =>
        (isCreation ? mutateCreateCluster : mutateUpdateCluster)({
          env: env,
          clusterGuid: clusterGuid,
          clusterRep: {
            name: values.name,
            guid: clusterGuid,
            status: values.status
          }
        })
    })
  }

  if (isLoadingCluster) {
    return <MerryGoRound />
  }
  if (errorGetCluster) {
    return <p>There was an error loading the cluster</p>
  }

  if (!isCreation && clusterGuid === 'default') {
    return (
      <>
        <p>
          The null / default cluster represents services that do not belong to
          any cluster. It is not explicitly defined and cannot be edited.
        </p>
        <ServicesInCluster clusterGuid={clusterGuid} />
      </>
    )
  } else {
    return (
      <>
        <div className='max-w-lg p-4 space-y-4 rounded-lg'>
          <Formik
            initialValues={{
              name: isCreation ? '' : cluster.name,
              status: isCreation ? 'Please select a status' : cluster.status
            }}
            onSubmit={onSubmitCluster}
          >
            {(formik) => {
              return (
                <Form id='edit-cluster-form'>
                  <h3 className='font-bold text-heading-3 text-secondary'>
                    {isCreation ? `Create` : `Edit`} Cluster
                  </h3>
                  <TextInputField
                    containerClassName='flex-grow'
                    name='name'
                    label='Name'
                    placeholder='cluster name'
                  />
                  <SelectField
                    options={['HEALTHY', 'UNHEALTHY', 'INACTIVE']}
                    name='status'
                    label='Status'
                  />
                  <div className='flex justify-end pt-4 space-x-4'>
                    <SubmitButton
                      disabled={
                        readOnly || isCreating || isUpdating || isLoadingCluster
                      }
                      form='edit-cluster-form'
                      className='flex-grow'
                      size='sm'
                    >
                      Save
                    </SubmitButton>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
        {!isCreation && <ServicesInCluster clusterGuid={clusterGuid} />}
      </>
    )
  }
}

export const ClusterDetailsPage = () => {
  const { env, id: clusterGuid } = useParams()

  return (
    <>
      <h5 className='type-headline-5'>
        {clusterGuid} in {env} environment
      </h5>

      <ClusterDetailsContainer isCreation={false} />
    </>
  )
}

export function ClusterCreationPage() {
  const { env } = useParams()

  return (
    <>
      <h5 className='type-headline-5'>Create cluster in {env} environment</h5>
      <ClusterDetailsContainer isCreation={true} />
    </>
  )
}
