import React from 'react'

export const AllEnvsRouteMapConfig = [
  {
    title: 'Dev',
    key: 'dev',
    routes: { elevations: 'http://service.eng.toastteam.com:14443' }
  },
  {
    title: 'Preproduction',
    key: 'pre',
    routes: { elevations: 'https://svcmgmt.pre.int.toasttab.com:14443' }
  },
  {
    title: 'Sandbox',
    key: 'sbx',
    routes: { elevations: 'https://svcmgmt.sbx.int.toasttab.com:14443' }
  },
  {
    title: 'Production',
    key: 'prod',
    routes: { elevations: 'https://svcmgmt.prod.int.toasttab.com:14443' }
  }
]
export const EnvRouteMapConfigContext = React.createContext(
  getEnvRouteMapConfig(true)
)
export function getEnvRouteMapConfig(dev) {
  if (dev) {
    return AllEnvsRouteMapConfig.slice(0, 1)
  } else {
    return AllEnvsRouteMapConfig.slice(1)
  }
}
