import React, { useContext } from 'react'
import { LabelledToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { useMutation, useQuery } from 'react-query'
import {
  fetchServiceMetadata,
  modifyServiceEnvoyAuthzEnabled
} from '../queries'
import { useParams } from 'react-router-dom'

import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { NotificationContext } from '../NotificationContainer'
import { PermissionsContext } from '../PermissionsContext'

const serviceTypeLookup = {
  SERVICE: 'G2 REST API or GraphQL API',
  SPA: 'Single Page JS Application',
  WORKER: 'Background Processor'
}
const rowClass = 'flex flex-row grid grid-cols-4 gap-4 mb-8'
export default function ServiceDefaults() {
  const { env, serviceName } = useParams()
  const { setSuccess, setError } = useContext(NotificationContext)
  const { readOnly } = useContext(PermissionsContext)
  const {
    data: serviceMetadata,
    error,
    serviceMetadataIsLoading,
    refetch: refetchServiceMetadata
  } = useQuery(
    ['fetchServiceMetadata', { env, serviceName }],
    () => fetchServiceMetadata({ env, serviceName }),
    {
      retry: 0,
      onError: (err) => {
        setError('Error loading Service Metadata', err)
      }
    }
  )
  const { mutate: setAuthz, isLoading: isSubmittingAuthz } = useMutation(
    (enabled) =>
      modifyServiceEnvoyAuthzEnabled({
        env: env,
        serviceName: serviceName,
        enabled: enabled
      }),
    {
      onSuccess: () => {
        setSuccess('AuthZ Successfully Updated')
      },
      onError: (err) => {
        setError('Error setting AuthZ', err)
      },
      onSettled: () => {
        return refetchServiceMetadata()
      }
    }
  )

  if (error) {
    return <div>Error loading defaults</div>
  }

  if (serviceMetadataIsLoading || !serviceMetadata) {
    return <MerryGoRound />
  }
  return (
    <>
      <div className={rowClass}>
        <div>
          <h5 className='type-headline-5'>Service Name</h5>
        </div>
        <div>
          <span className='pl-2 type-caption text-default'>
            {serviceMetadata.name}
          </span>
        </div>
      </div>
      <div className={rowClass}>
        <div>
          <h5 className='type-headline-5'>Service Type</h5>
        </div>
        <div>
          <span className='pl-2 type-caption text-default'>
            {serviceTypeLookup[serviceMetadata.resourceType.toUpperCase()]}
          </span>
        </div>
      </div>
      <div className={rowClass}>
        <div>
          <h5 className='type-headline-5'>EnvoyAuthz</h5>
          <span className='type-caption text-secondary'>
            (Change the default value for deployments of this service)
          </span>
        </div>
        <div>
          <LabelledToggleSwitch
            isActive={serviceMetadata.envoyAuthzEnabled}
            disabled={readOnly || isSubmittingAuthz}
            onChange={(v) => {
              setAuthz(!serviceMetadata.envoyAuthzEnabled)
            }}
            size='xs'
            labels={['Disabled', 'Enabled']}
          />
        </div>
      </div>
    </>
  )
}
