import React, { useState, Fragment, useContext, useRef, useEffect } from 'react'
import { Cell, Row } from '@toasttab/buffet-pui-table'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import {
  InCellCancelButton,
  InCellDeleteButton,
  InCellEditButton,
  InCellSubmitButton
} from '../EditingButtons'
import { useMutation } from 'react-query'
import { addABElevation, removeABElevation } from '../../queries'
import { useParams } from 'react-router-dom'
import { NotificationContext } from '../../NotificationContainer'

export const ABElevationRow = ({
  elevation,
  editing,
  setEditing,
  disabled,
  onSuccess
}) => {
  const { env, serviceName } = useParams()
  const [updateElevationInput, setUpdateElevationInput] = useState(
    elevation.percentageOfRequests * 100
  )
  const { setError, setSuccess } = useContext(NotificationContext)
  const { mutate: addABE, isLoading: addLoading } = useMutation(
    ({ percentage }) =>
      addABElevation({
        env,
        serviceName,
        version: elevation.service.sversion,
        revision: elevation.service.srevision,
        deployment: elevation.service.deployment,
        percentage
      }),
    {
      onError: (err) => {
        setError('Error Updating AB Elevation', err)
      },
      onSuccess: (res, { percentage }) => {
        setEditing(false)
        setSuccess(
          `Updated AB Elevation ${serviceName} V:${elevation.service.srevision} D:${elevation.service.deployment} with ${percentage}% of traffic.`
        )
        return onSuccess()
      }
    }
  )
  const { mutate: removeABE, isLoading: removeLoading } = useMutation(
    () =>
      removeABElevation({
        env,
        serviceName,
        version: elevation.service.sversion,
        revision: elevation.service.srevision,
        deployment: elevation.service.deployment
      }),
    {
      onError: (err) => {
        setError(`Error Deleting AB Elevation:`, err)
      },
      onSuccess: () => {
        setSuccess(
          `Deleted AB Elevation ${serviceName} V:${elevation.service.srevision} D:${elevation.service.deployment}.`
        )
        return onSuccess()
      }
    }
  )
  const input = useRef(null)
  useEffect(() => {
    if (editing && input.current) {
      input.current.focus() //focus on input if we are about to enter edit mode
    }
  }, [editing])
  let buttonCell
  if (removeLoading) {
    buttonCell = <MerryGoRound size={MerryGoRound.Size.xxs} />
  } else if (editing) {
    buttonCell = (
      <Fragment>
        <InCellSubmitButton
          handleClick={() => addABE({ percentage: updateElevationInput })}
          disabled={addLoading}
        />
        <InCellCancelButton handleClick={() => setEditing(false)} />
      </Fragment>
    )
  } else {
    buttonCell = (
      <Fragment>
        <InCellEditButton
          handleClick={() => {
            setUpdateElevationInput(elevation.percentageOfRequests * 100)
            setEditing(!editing)
          }}
          disabled={disabled}
        />
        <InCellDeleteButton
          handleClick={() => removeABE()}
          disabled={disabled}
        />
      </Fragment>
    )
  }
  return (
    <Row>
      <Cell>
        {editing ? (
          <input
            ref={input}
            type='number'
            step={1}
            className='in-cell-input'
            onChange={(event) => setUpdateElevationInput(event.target.value)}
            value={updateElevationInput}
          />
        ) : (
          <span> {(elevation.percentageOfRequests * 100).toFixed(0)} </span>
        )}
      </Cell>
      <Cell>{elevation.service.srevision}</Cell>
      <Cell>{elevation.service.deployment}</Cell>
      <Cell>
        <span className='flex flex-row justify-end'>{buttonCell}</span>
      </Cell>
    </Row>
  )
}
