import React, { useContext } from 'react'
import { updateServiceStatus } from '../queries'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { LabelledToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { NotificationContext } from '../NotificationContainer'
import { PermissionsContext } from '../PermissionsContext'

const ActivateServiceToggle = ({
  service,
  serviceDeployments,
  refetchServiceDeployments
}) => {
  const { env } = useParams()
  const { setError, setSuccess, confirm } = useContext(NotificationContext)
  const { readOnly } = useContext(PermissionsContext)

  const { mutate: toggleServiceStatus, isLoading } = useMutation(
    () =>
      updateServiceStatus({
        env,
        service,
        active: !service.active
      }),
    {
      onError: (err) => {
        setError(
          `Error ${service.active ? 'Deactivating' : 'Activating'} Service.`,
          err
        )
      },
      onSuccess: () => {
        setSuccess(`Successfully Toggled Service Status`)
      },
      onSettled: () => refetchServiceDeployments()
    }
  )
  const operationName = service.active
    ? 'Deactivate Service'
    : 'Activate Service'
  return (
    <LabelledToggleSwitch
      isActive={service.active}
      size='xs'
      labels={isLoading ? ['Loading', 'Loading'] : ['Inactive', 'Active']}
      disabled={readOnly || isLoading}
      onChange={() => {
        confirm({
          service,
          serviceDeployments,
          operationName,
          onConfirm: toggleServiceStatus
        })
      }}
    />
  )
}
export default ActivateServiceToggle
