import React from 'react'

export const PermissionsContext = React.createContext({
  readOnly: true
})

/*
  TODO
    Query for permissions to determine readOnly
 */
