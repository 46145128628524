export function errorOrMessageToString(message) {
  if (!message) {
    return ''
  } else if (typeof message === 'string') {
    return message
  } else if (isServiceNotFoundError(message)) {
    return 'Service not found in svcmgmt. Please check that this is a registered service!'
  } else if (isVPNError(message)) {
    return 'Please check that you are on the correct VPN!'
  } else if (typeof message === 'object' && message['message']) {
    return message.message
  } else {
    try {
      return JSON.stringify(message)
    } catch {
      console.log('Unprintable message message', message)
      return 'Unprintable message message'
    }
  }
}

function writeCsvString(headers, data) {
  return (
    headers.join(',') + '\r\n' + data.map((row) => row.join(',')).join('\r\n')
  )
}
export function writeToCsvBlob(headers, data) {
  return new Blob([writeCsvString(headers, data)], { type: 'text/csv' })
}

function isVPNError(err) {
  return (
    !err.status &&
    ((err.message && err.message.includes('fetch')) ||
      err.message.includes('Load failed'))
  ) // "Load failed for Safari, `fetch` for chrome/firefox
}

function isServiceNotFoundError(err) {
  return err.status && err.status === 404
}
