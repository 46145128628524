import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { fetchService } from '../queries'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { errorOrMessageToString } from '../util'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import ActivateServiceToggle from './ActivateServiceToggle'
import { ClusterList } from './ClusterList'

export const ServiceDeploymentRow = ({
  service,
  serviceDeployments,
  refetchServiceDeployments
}) => {
  return (
    <Row>
      <Cell>
        <ActivateServiceToggle
          service={service}
          serviceDeployments={serviceDeployments}
          refetchServiceDeployments={refetchServiceDeployments}
        />
      </Cell>
      <Cell>{service.srevision}</Cell>
      <Cell>{service.deployment}</Cell>
      <Cell>
        <ClusterList service={service} />
      </Cell>
      <Cell>
        <a href={service.uri}>uri</a>
      </Cell>
      <Cell>
        <span>{service.envoyAuthzEnabled ? 'enabled' : 'disabled'}</span>
      </Cell>
      <Cell>{service.comment}</Cell>
    </Row>
  )
}

export default function ServiceDeploymentList() {
  const { env, serviceName } = useParams()
  const {
    data: serviceDeployments,
    error: serviceDeploymentsLoadingError,
    isLoading: isLoadingServiceDeployments,
    refetch: refetchServiceDeployments
  } = useQuery(['fetchService', { env, serviceName }], () =>
    fetchService({ env, serviceName })
  )

  const isLoading = isLoadingServiceDeployments

  if (isLoading) {
    return <MerryGoRound />
  }
  if (serviceDeploymentsLoadingError) {
    return (
      <p>
        An error happened looking for the service named {serviceName}:{' '}
        {errorOrMessageToString(serviceDeploymentsLoadingError)}
      </p>
    )
  }

  if (serviceDeployments.length === 0) {
    return <p>No services found by the name of {serviceName}.</p>
  }

  return (
    <div className='mt-8'>
      <h5 className='type-headline-5'>Service Deployments</h5>
      <Table className='table-fixed'>
        <Head>
          <Row>
            <HeadingCell className='w-96 text-center'>Active</HeadingCell>
            <HeadingCell className='w-24 text-center'>Revision</HeadingCell>
            <HeadingCell className='w-36 text-center'>Deployment</HeadingCell>
            <HeadingCell className='w-24 text-center'>Cluster</HeadingCell>
            <HeadingCell className='w-24 text-center'>Link</HeadingCell>
            <HeadingCell className='w-48 text-center'>
              EnvoyAuthzEnabled
            </HeadingCell>
            <HeadingCell className='text-center'>Comment</HeadingCell>
          </Row>
        </Head>
        <Body>
          {[...serviceDeployments]
            .sort((s1, s2) => parseInt(s1.sversion) - parseInt(s2.sversion))
            .sort((s1, s2) => parseInt(s1.srevision) - parseInt(s2.srevision))
            .sort((s1, s2) => parseInt(s1.deployment) - parseInt(s2.deployment))
            .map((service) => (
              <ServiceDeploymentRow
                key={
                  service.name +
                  service.sversion +
                  service.srevision +
                  service.deployment
                }
                service={service}
                serviceDeployments={serviceDeployments}
                refetchServiceDeployments={refetchServiceDeployments}
              />
            ))}
        </Body>
      </Table>
    </div>
  )
}
